.zent-tabs {
 flex-direction: row;
 display: flex;
 min-height: 550px;
 width: 100%;
}
.zent-tabs-nav {
    border-right: 1px solid #dcdee0;
    /* width: 130px; */
    padding: 14px 7px 14px 0;
    position: relative;
    outline: none;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: start;
    justify-content: flex-start;
    box-sizing: border-box;
    height: 290px;
}
.zent-tabs-nav-content {
    flex: 1 1 auto;
    overflow: hidden;
}
.zent-tabs-scroll {
    overflow-x: hidden;
    overflow-y: auto;
    width: 103px;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 120px!important;
    white-space: nowrap;
    display: flex;
}
.zent-tabs-tab {
    display: flex;
    text-align: left;
    border-radius: 2px;
    padding: 8px;
    overflow: hidden;
    cursor: pointer;
    box-sizing: border-box;
    text-overflow: ellipsis;
    align-items: center;
    width: 120px!important;
}
.zent-tabs-tab:nth-child(n+2){
    margin-top: 4px;
}
.color-bg{
    background-color: #ebedf0;
}
.zent-tabs-divide {
    border-bottom: 1px solid #ebedf0;
    margin: 21.5px 0 17.5px;
}
._2-g0 {
    color: #0059dc;
}
._1xhH {
    margin-right: 16px;
    margin-left: 16px;
    width: 290px;
    height: 290px;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: #f7f8fa;
    text-align: center;
}