@import "~antd/dist/antd.css";

html {
  /* rem em */
  /* em 相对于父元素的font-size */
  /* rem 相对于根元素html的font-size, r就是root的意思 */
  /* 16 * 62.5% = 10px */
  /* 1rem === 10px */
  font-size: 62.5%;
}

html body #root .App {
  min-height: 100vh;
}